import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../redux/actions/modalActions";
import variables from "../../styles/utils/variables.scss";
import { resetCss } from "../../utils/modal";
import { Icon } from "../icons/Icon";

interface Props {
  auth0: any;
  hideModal: any;
}
class SignUpPromptPopup extends Component<Props> {
  closeModal = () => {
    resetCss();
    this.props.hideModal();
  };

  showSignUp = () => {
    const redirect_url = window.location.origin.toString() + "/signup";
    this.props.auth0.loginWithRedirect({
      login_hint: "signup",
      redirect_url,
    });
  };

  render() {
    const violet = variables.violetColor;
    return (
      <div className="u-br--small o-modal__container">
        <div
          className="o-modal--content-centered u-modal--content-bottom--mobile u-w-all--mobile u-relative"
          style={{
            width: window.innerWidth > 600 ? "500px" : "100%",
          }}
        >
          <button
            className="u-absolute"
            style={{ top: 18, right: 18 }}
            onClick={() => this.closeModal()}
          >
            <Icon id="clear" stroke="grey" size="s" />
          </button>
          <div
            style={{
              fontSize: "14px",
            }}
            className="u-main-font--small u-color-charcoal u-background--white u-br--small"
          >
            <div className="u-hide--desktop" style={{ paddingTop: 18 }}>
              <Icon id="grocerize" fill={violet} width={115} height={29} />
            </div>
            <div className="u-ph--48 u-text-center u-mauto u-pb--30--desktop">
              <div className="u-hide--mobile u-pt--48--desktop">
                <Icon id="grocerize" fill={violet} width={115} height={48} />
              </div>
              <div className=" u-align-middle u-w-all u-text-center u-pb--48 u-pt--20">
                <div className="u-main-font--small u-bold u-pb--40--mobile">
                  Don’t loose your list, sign up now
                </div>
                <div className="u-pt--24--desktop u-ph--8 u-pt--12--mobile">
                  Its free to join thousands of users using Grocerize. Sign up
                  today and unlock value add features like setting price alerts
                  and saving shopping lists.
                </div>
              </div>
            </div>
            <button
              className="u-w-all u-background--magenta u-pv--20 u-bold u-color-white u-br--bottom--small"
              onClick={() => this.showSignUp()}
            >
              Sign up now
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withAuth0(
  connect(null, {
    hideModal,
  })(SignUpPromptPopup)
);
